<template>
  <div class="flex justify-center mt-20 cursor-pointer overflow-y-scroll">
    <div v-html="impressum" class="text-left" />
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      impressum: null,
    }
  },
  mounted() {
    axios
      .post(`${import.meta.env.VITE_AXIOS_BASEURL}/api/impressum`, {
        lang: 'de',
      })
      .then((res) => {
        this.impressum = res.data
      })
  },
}
</script>
