<template>
  <div class="w-full h-full flex flex-col justify-center items-center">
    <div class="w-8/12 bg-fcn-blue border-3 border-fcn-orange-light rounded-xl">
      <div class="text-white text-3xl pt-6">
        <div class="ml-5">{{ title }}</div>
      </div>
      <div v-if="list.length > 0" class="flex flex-col p-8 justify-center">
        <div class="p-2 w-10/12 self-center text-lg uppercase">
          <div class="grid grid-cols-5 text-white">
            <div>Datum</div>
            <div class="col-span-2">Thema</div>
            <div>Meeting-ID</div>
            <div>Code</div>
          </div>
        </div>
        <div
          v-for="(item, i) in list"
          :key="`item${i}`"
          :index="i"
          class="p-2 w-10/12 self-center hover:underline hover:cursor-pointer"
        >
          <div class="grid grid-cols-5 text-white">
            <div>
              <a
                :href="item.link"
                target="_blank"
                :alt="`Link zu ${item.topic}`"
                >{{ date(item.date, item.time) }}</a
              >
            </div>
            <div class="col-span-2">
              <a
                :href="item.link"
                target="_blank"
                :alt="`Link zu ${item.topic}`"
                >{{ item.topic }}</a
              >
            </div>
            <div>
              <a
                :href="item.link"
                target="_blank"
                :alt="`Link zu ${item.topic}`"
                >{{ item.meetingid }}</a
              >
            </div>
            <div>
              <a
                :href="item.link"
                target="_blank"
                :alt="`Link zu ${item.topic}`"
                >{{ item.code }}</a
              >
            </div>
          </div>
          <!--           <div v-if="i < list.length - 1" class="w-full flex justify-center">
            <div class="border border-fcn-orange-light w-1/12 my-2" />
          </div> -->
        </div>
        <div class="text-white mt-4 text-sm">
          Klicken Sie auf das gewünschte Webinar und seien Sie live dabei.
        </div>
      </div>
      <div v-else class="p-8 text-white">
        Derzeit sind keine Webinare geplant.
      </div>
    </div>
  </div>
</template>

<script>
import axios from 'axios'
export default {
  data() {
    return {
      list: [],
    }
  },
  methods: {
    date(d, t) {
      const date = new Date(d)
      return `${date.toLocaleDateString('de-DE')} - ${t.slice(0, 5)} Uhr`
    },
  },
  props: {
    title: {
      type: String,
      default: null,
    },
    src: {
      type: String,
      default: null,
    },
  },
  mounted() {
    axios
      .get(`${import.meta.env.VITE_AXIOS_BASEURL}/api/${this.src}`)
      .then((res) => {
        this.list = res.data
      })
  },
}
</script>

<style>
.bgimgsize {
  background-size: 100% 100%;
}
</style>
