<template>
  <div class="exitBtn" :class="[topSpace ? 'top-space' : 'no-top-space']">
    <div class="w-full h-full absolute bg-transparent" @click="$emit('exit')" />
    <object data="/assets/img/icons/close.svg" type="image/svg+xml" />
  </div>
</template>

<script>
export default {
  props: {
    topSpace: {
      type: Boolean,
      default: true,
    },
  },
}
</script>

<style>
.exitBtn {
  @apply w-10 h-10  absolute text-fcn-orange font-bold text-5xl  cursor-pointer z-50;
}
.top-space {
  @apply top-20 right-3;
}
.no-top-space {
  @apply right-0 mr-8 mt-8 top-0;
}
</style>
