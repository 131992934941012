export default {
  state: () => {
    return {
      roomData: null,
      showELearning: false,
      showELearningGallery: false,
      showWorkshopPDFDownload: false,
      showWorkshopCameraGallery: false,
    }
  },
  mutations: {
    setRoomData(state, data) {
      state.roomData = data
    },
    openshowELearning(state) {
      state.showELearning = true
    },
    closeshowELearning(state) {
      state.showELearning = false
    },
    openshowELearningGallery(state) {
      state.showELearningGallery = true
    },
    closeshowELearningGallery(state) {
      state.showELearningGallery = false
    },
    openshowWorkshopPDFDownload(state) {
      state.showWorkshopPDFDownload = true
    },
    closeshowWorkshopPDFDownload(state) {
      state.showWorkshopPDFDownload = false
    },
    openshowWorkshopCameraGallery(state) {
      state.showWorkshopCameraGallery = true
    },
    closeshowWorkshopCameraGallery(state) {
      state.showWorkshopCameraGallery = false
    },
  },
  actions: {},
  getters: {},
}
