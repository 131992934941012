<template>
  <div class="flex justify-center mt-20 cursor-pointer overflow-y-scroll">
    <div class="grid gap-x-5 gap-y-3 grid-cols-2 md:grid-cols-3">
      <video
        v-for="(item, i) in content.content"
        :key="`${i}`"
        :index="i"
        muted
        class="
          w-96
          max-w-2/5-vw
          md:max-w-3/10-vw
          h-23-vw
          md:h-55 md:max-h-17-1/4-vw
          border-3 border-fcn-orange-light
          cursor-pointer
        "
        :src="$cmsFile(item.src)"
        @click="
          $store.commit('index/openVideo', { src: $cmsFile(item.src), time: 0 })
        "
      />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>
