<template>
  <div class="flex items-center">
    <div v-show="showDetail" class="detail">
      <img
        :src="current.img"
        :alt="current.title"
        class="object-contain h-64 m-2"
      />
      <div class="text-xl font-bold uppercase" v-text="current.title" />
      <div
        v-if="current.subtitle"
        class="text-sm italic"
        v-text="current.subtitle"
      />
      <div
        v-if="current.text"
        class="text-base w-2/3 m-2 text-center self-center"
        v-html="current.text"
      />
      <a v-if="current.link" :href="current.link" target="_blank">Download</a>
    </div>
    <div class="grid grid-cols-3 gap-4 z-10 overflow-y-auto h-full">
      <div v-for="(item, index) in content" :key="index">
        <img
          :src="item.img"
          :alt="item.title"
          class="cursor-pointer"
          @click="openDetail(item)"
        />
        <div class="font-bold">{{ item.title }}</div>
        <div class="text-xs">{{ item.subtitle }}</div>
      </div>
    </div>
    <div
      v-show="showDetail"
      class="left-0 top-0 absolute w-screen h-screen bg-black bg-opacity-75 z-0"
      @click="closeDetail"
    />
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Array,
      default() {
        return []
      },
    },
  },
  data() {
    return {
      showDetail: false,
      current: {
        title: '',
        subtitle: '',
        text: '',
        img: '',
        file: '',
      },
    }
  },
  methods: {
    openDetail(item) {
      this.current = item
      this.showDetail = true
    },
    closeDetail() {
      this.showDetail = false
    },
  },
}
</script>

<style>
.detail {
  @apply w-6/12 h-1/2 bg-white absolute z-40 flex flex-col justify-items-center justify-center;
}
</style>
