//JS
import { createApp } from 'troisjs'
import { createStore } from 'vuex'
import VueMatomo from 'vue-matomo'
import cmsFile from './plugins/cmsFile'
import cmsModel from './plugins/cmsModel'
import index from './store/index'
import room1 from './store/room1'
import room2 from './store/room2'
import room3 from './store/room3'
import room5 from './store/room5'
import room6 from './store/room6'
import App from './App.vue'
import Grid from './components/elements/Grid.vue'
import Gallery from './components/elements/Gallery.vue'
import GeneralGallery from './components/elements/GeneralGallery.vue'
import Photogrid from './components/elements/Photogrid.vue'
import Dialogbox from './components/elements/Dialogbox.vue'
import Downloadgrid from './components/elements/Downloadgrid.vue'
import Linklist from './components/elements/Linklist.vue'
import ExitBtn from './components/elements/ExitBtn.vue'
import Videogrid from './components/elements/Videogrid.vue'
import Impressum from './components/elements/Impressum.vue'
import router from './router'
//CSS
import './index.css'

const store = createStore({
  modules: {
    index: { namespaced: true, ...index },
    room1: { namespaced: true, ...room1 },
    room2: { namespaced: true, ...room2 },
    room3: { namespaced: true, ...room3 },
    room5: { namespaced: true, ...room5 },
    room6: { namespaced: true, ...room6 },
  },
})

const app = createApp(App)
  .use(router)
  .component('Grid', Grid)
  .component('Gallery', Gallery)
  .component('GeneralGallery', GeneralGallery)
  .component('Photogrid', Photogrid)
  .component('Dialogbox', Dialogbox)
  .component('Downloadgrid', Downloadgrid)
  .component('Linklist', Linklist)
  .component('ExitBtn', ExitBtn)
  .component('Videogrid', Videogrid)
  .component('Impressum', Impressum)

// app.use(VueMatomo, {
//   host: import.meta.env.VITE_MATOMO_URL,
//   siteId: import.meta.env.VITE_MATOMO_ID,
//   router: router,
//   trackerFileName: 'matomo',
//   enableLinkTracking: true,
//   requireConsent: false,
//   trackInitialView: true,
//   disableCookies: true,
//   requireCookieConsent: true,
//   enableHeartBeatTimer: true,
//   heartBeatTimerInterval: 15,
// })

//Helpers
app.use(store)
app.use(cmsFile)
app.use(cmsModel)
app.mount('#app')
