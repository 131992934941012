export default {
  state: () => {
    return {
      generalVideo: false,
      generalGallery: false,
      generalPhotogrid: false,
      generalPhotogridContent: null,
      generalFilegrid: false,
      generalFilegridContent: null,
      generalVideogrid: false,
      generalVideogridContent: null,
      generalIFrame: false,
      generalIFrameContent: null,
      generalImpressum: false,
      generalSrc: '',
      generalImgs: null,
      generalTime: 0,
      postEvent: '',
      register: null,
      showTutorial: false,
    }
  },
  mutations: {
    showTutorial(state) {
      state.showTutorial = true
    },
    hideTutorial(state) {
      state.showTutorial = false
    },
    loadregister(state) {
      state.register = JSON.parse(sessionStorage.getItem('registerdata'))
    },
    saveregister(state, payload) {
      state.register = payload
      sessionStorage.setItem('registerdata', JSON.stringify(payload))
    },
    openVideo(state, payload) {
      state.generalVideo = true
      state.generalSrc = payload.src
      state.generalTime = payload.time
      state.postEvent = payload.evt
    },
    closeVideo(state) {
      if (state.postEvent) {
        //allows for video playback right after the prior video
        state.generalSrc = state.postEvent
        state.generalTime = 0
        state.postEvent = null
      } else {
        state.generalSrc = null
        state.generalVideo = false
        state.generalTime = 0
      }
    },
    openGallery(state, payload) {
      state.generalImgs = payload.src
      state.generalGallery = true
    },
    closeGallery(state) {
      state.generalGallery = false
    },
    openPhotogrid(state, payload) {
      state.generalPhotogridContent = payload.src
      state.generalPhotogrid = true
    },
    closePhotogrid(state) {
      state.generalPhotogrid = false
    },
    openFilegrid(state, payload) {
      state.generalFilegridContent = payload.src
      state.generalFilegrid = true
    },
    closeFilegrid(state) {
      state.generalFilegrid = false
    },
    openIFrame(state, payload) {
      state.generalIFrameContent = payload.src
      state.generalIFrame = true
    },
    closeIFrame(state) {
      state.generalIFrame = false
    },
    openImpressum(state) {
      state.generalImpressum = true
    },
    closeImpressum(state) {
      state.generalImpressum = false
    },
    openVideogrid(state, payload) {
      state.generalVideogridContent = payload.src
      state.generalVideogrid = true
    },
    closeVideogrid(state) {
      state.generalVideogrid = false
    },
  },
  actions: {},
  getters: {},
}
