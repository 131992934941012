<template>
  <div class="fixed flex justify-between top-0 w-full h-14 bg-fcn-blue z-30">
    <img class="mt-2 ml-3 h-10" src="../../public/assets/img/icons/fcn.svg" />
    <div class="mr-5 mt-4 block cursor-pointer" @click="toggleHeadermenu">
      <!-- Hier wird das Logo mit den drei orangefarbigen Strichen angefragt -->
      <div v-if="!openHeadermenu && isExpoOpen">
        <div class="bg-fcn-orange-light h-1 w-8 mb-1.5"></div>
        <div class="bg-fcn-orange-light h-1 w-8"></div>
        <div class="bg-fcn-orange-light h-1 w-8 mt-1.5"></div>
      </div>
      <div v-if="openHeadermenu">
        <img class="h-8 ml-2" src="../../public/assets/img/icons/close.svg" />
      </div>
    </div>
  </div>
  <div
    v-if="openHeadermenu"
    class="fixed top-14 right-0 w-72 bg-fcn-blue text-white text-xl uppercase cursor-pointer"
  >
    <div class="pt-3 w-full bg-fcn-blue"></div>
    <div v-for="(item, index) in headersection" :key="index">
      <div
        class="pl-5 leading-12 headersection_line"
        @click="menuClick(item.action, item.param)"
      >
        {{ item.title }}
      </div>
    </div>
    <div class="pt-3 w-full bg-fcn-blue"></div>
  </div>
</template>

<script>
export default {
  emits: ['menuclick', 'playvideo', 'room', 'impressum', 'externallink'],
  props: {
    isExpoOpen: {
      type: Boolean,
      default: false,
    },
    headersection: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      openHeadermenu: false,
    }
  },
  mounted() {},
  methods: {
    toggleHeadermenu() {
      this.openHeadermenu = !this.openHeadermenu
    },
    menuClick(action, param) {
      switch (action) {
        case 'tutorial':
          this.$store.commit('index/showTutorial')
          break
        case 'bedienung':
          action = 'playvideo'
          param = `${import.meta.env.VITE_AXIOS_BASEURL}/${
            this.$store.state.room1.roomData?.tutorial_video.content[0].src
          }`
          break
      }
      this.$emit(action, param)
      this.toggleHeadermenu()
    },
  },
}
</script>

<style>
/* Hier wird noch das Hoverdesign angefragt */
.headersection_line:hover {
  @apply bg-fcn-orange;
}
</style>
