<template>
  <div>
    <div class="w-full h-full">
      <div
        class="
          w-108
          h-44
          bg-fcn-blue
          border-3 border-fcn-orange-light
          rounded-xl
        "
      >
        <div class="text-center text-white pt-8 pb-6 text-xl">
          {{ message }}
        </div>
        <div class="flex justify-center cursor-pointer">
          <div @click="dialogFunction()" class="text-btn mr-4">
            {{ btn1text }}
          </div>
          <button @click="closeDialog()" class="text-btn">
            {{ btn2text }}
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    message: {
      type: String,
      default: null,
    },
    btn1text: {
      type: String,
      default: null,
    },
    btn2text: {
      type: String,
      default: null,
    },
  },
  methods: {
    closeDialog() {
      this.$emit('dialogclose')
    },
    dialogFunction() {
      this.$emit('dialogfunction')
    },
  },
}
</script>

<style>
.text-btn {
  @apply flex
        w-44
        h-12
        bg-fcn-blue-dark
        text-white text-xl
        rounded
        justify-center
        items-center
        right-10;
}
</style>
