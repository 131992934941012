export default {
  state: () => {
    return {
      roomData: null,
      showIntro: true,
      showGallery: false,
      showCommunications: false,
      showCommunicationsPDFDownload: false,
    }
  },
  mutations: {
    setRoomData(state, data) {
      state.roomData = data
    },
    loadIntroVideo(state) {
      const res = localStorage.getItem('room6_introvideo')
      state.showIntro = res !== 'false'
    },
    shownIntroVideo(state) {
      state.showIntro = false
      localStorage.setItem('room6_introvideo', state.showIntro)
    },
    openshowGallery(state) {
      state.showGallery = true
    },
    closeshowGallery(state) {
      state.showGallery = false
    },
    openshowCommunications(state) {
      state.showCommunications = true
    },
    closeshowCommunications(state) {
      state.showCommunications = false
    },
    openshowCommunicationsPDFDownload(state) {
      state.showCommunicationsPDFDownload = true
    },
    closeshowCommunicationsPDFDownload(state) {
      state.showCommunicationsPDFDownload = false
    },
  },
  actions: {},
  getters: {},
}
