<template>
  <div class="bg-fcn-orange text-white fixed right-0 top-10 z-50 text-sm">
    <div>X: {{ pos.x }}</div>
    <div>Y: {{ pos.y }}</div>
    <div>Z: {{ pos.z }}</div>
  </div>
</template>

<script>
export default {
  props: {
    pos: {
      type: Object,
      default() {
        return {}
      },
    },
  },
}
</script>

<style></style>
