<template>
  <div class="flex justify-center overflow-auto">
    <div>
      <div
        class="
          flex
          items-center
          rounded-xl
          h-14
          bg-fcn-blue
          text-white text-3xl
        "
      >
        <div class="ml-5">{{ content.options?.title }}</div>
      </div>
      <div class="grid gap-5 grid-cols-4 mt-4">
        <div
          v-for="(item, i) in content.content"
          :key="`download_option_${i}`"
          :index="i"
          class="
            relative
            border-3
            rounded-xl
            border-fcn-orange-lightorange
            bg-cover bg-center
          "
          :style="{
            'background-image': 'url(' + bg + ')',
          }"
        >
          <a :href="$cmsFile(item.src)" download target="_blank">
            <div class="h-32 w-48">
              <div
                :class="[
                  item.options?.text.length < 21 ? 'text-lg' : 'text-sm',
                ]"
                class="
                  absolute
                  bottom-0
                  rounded-b-xl
                  bg-fcn-blue-dark
                  text-white
                  h-10
                  w-full
                  flex
                  items-center
                  justify-center
                "
                v-text="item.options?.text"
              ></div>
              <div class="w-full absolute flex justify-center bottom-2/5">
                <img
                  class="
                    h-12
                    w-12
                    bg-fcn-blue-dark
                    opacity-71
                    border-6 border-fcn-blue-dark border-opacity-71
                    rounded-lg
                  "
                  src="/assets/img/icons/download.svg"
                />
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    content: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  data() {
    return {
      bg: '/assets/img/downloadbg.jpg',
    }
  },
}
</script>
