import { createRouter, createWebHistory } from 'vue-router'
import App from '../App.vue'

const base = '/'
const routerHistory = createWebHistory(base)

export default createRouter({
  history: routerHistory,
  routes: [
    {
      path: '/',
      name: 'Digitale Messe',
      component: App,
    },
  ],
})
