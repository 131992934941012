export default {
  state: () => {
    return {
      roomData: null,
      showIntro: true,
      showExplainVideos: false,
      showDroneGallery: false,
      showShelfcameraGallery: false,
      showOmnidirectionalCameraGallery: false,
      showBehindTheScenesGallery: false,
      showOtherLocationsGallery: false,
      showPostproductionGallery: false,
      showVideocutGallery: false,
      showLivecutGallery: false,
    }
  },
  mutations: {
    setRoomData(state, data) {
      state.roomData = data
    },
    loadIntroVideo(state) {
      const res = localStorage.getItem('room2_introvideo')
      state.showIntro = res !== 'false'
    },
    shownIntroVideo(state) {
      state.showIntro = false
      localStorage.setItem('room2_introvideo', state.showIntro)
    },
    openshowExplainVideos(state) {
      state.showExplainVideos = true
    },
    closeshowExplainVideos(state) {
      state.showExplainVideos = false
    },
    openshowDroneGallery(state) {
      state.showDroneGallery = true
    },
    closeshowDroneGallery(state) {
      state.showDroneGallery = false
    },
    openshowShelfcameraGallery(state) {
      state.showShelfcameraGallery = true
    },
    closeshowShelfcameraGallery(state) {
      state.showShelfcameraGallery = false
    },
    openshowOmnidirectionalCameraGallery(state) {
      state.showOmnidirectionalCameraGallery = true
    },
    closeshowOmnidirectionalCameraGallery(state) {
      state.showOmnidirectionalCameraGallery = false
    },
    openshowBehindTheScenesGallery(state) {
      state.showBehindTheScenesGallery = true
    },
    closeshowBehindTheScenesGallery(state) {
      state.showBehindTheScenesGallery = false
    },
    openshowOtherLocationsGallery(state) {
      state.showOtherLocationsGallery = true
    },
    closeshowOtherLocationsGallery(state) {
      state.showOtherLocationsGallery = false
    },
    openshowPostproductionGallery(state) {
      state.showPostproductionGallery = true
    },
    closeshowPostproductionGallery(state) {
      state.showPostproductionGallery = false
    },
    openshowVideocutGallery(state) {
      state.showVideocutGallery = true
    },
    closeshowVideocutGallery(state) {
      state.showVideocutGallery = false
    },
    openshowLivecutGallery(state) {
      state.showLivecutGallery = true
    },
    closeshowLivecutGallery(state) {
      state.showLivecutGallery = false
    },
  },
  actions: {},
  getters: {},
}
