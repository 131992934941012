<template>
  <carousel
    v-if="contentlength > 0"
    :items-to-show="2"
    :wrapAround="true"
    class="w-11/12 h-full flex flex-col justify-center"
  >
    <slide v-for="slide in contentlength" :key="slide" class="self-center">
      <img
        :src="$cmsFile(content[slide - 1].src)"
        :alt="
          content[slide - 1].options?.title
            ? content[slide - 1].options.title
            : `gallery image ${slide}`
        "
        class="h-2/3 carousel__item max-h-66vh"
      />
    </slide>
    <template #addons="{ slidesCount }">
      <Navigation v-if="slidesCount > 1" />
      <Pagination v-if="slidesCount > 1" />
    </template>
  </carousel>
</template>

<script>
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel'

export default {
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  props: {
    content: {
      type: Object,
      default() {
        return {}
      },
    },
  },
  computed: {
    contentlength() {
      return this.content?.length ? this.content.length : 0
    },
  },
}
</script>
<style>
.carousel__icon {
  width: 1.2em;
  height: 1.2em;
  fill: currentColor;
}
.carousel {
  @apply relative text-center box-border;
}

.carousel * {
  @apply box-border;
}

.carousel__track {
  @apply flex m-0 p-0 relative;
}

.carousel__viewport {
  @apply overflow-hidden;
}
.carousel__pagination {
  @apply flex justify-center list-none;
}
.carousel__pagination-button {
  @apply cursor-pointer bg-fcn-blue-dark border-0 rounded-full h-4 w-4 mx-1 my-4;
}

.carousel__pagination-button--active {
  @apply bg-fcn-orange-light;
}

.carousel__slide {
  scroll-snap-stop: auto;
  @apply flex justify-center items-center relative m-0 flex-shrink-0;
}

.carousel__prev,
.carousel__next {
  @apply text-fcn-orange-light bg-transparent cursor-pointer text-center flex border-0 absolute justify-center items-center p-0 w-20 h-20 rounded-full text-6xl;
}

.carousel__prev {
  @apply transform -translate-x-1/2 -translate-y-1/2 left-0 top-1/2;
}

.carousel__next {
  @apply transform translate-x-1/2 -translate-y-1/2 right-0 top-1/2;
}

.carousel__slide--next > .carousel__item {
  transform: scale(0.7) translate(-3rem);
}
.carousel__slide--prev > .carousel__item {
  transform: scale(0.7) translate(3rem);
}
</style>
