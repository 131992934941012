export default {
  install: (app) => {
    function cmsModel(filename, ext = 'glb' ) {
      return `${import.meta.env.VITE_AXIOS_BASEURL}/models/${
        import.meta.env.VITE_PROJECT_ID
      }/${filename}/${filename}.${ext}`
    }

    app.config.globalProperties.$cmsModel = cmsModel
  },
}
