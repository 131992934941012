export default {
  state: () => {
    return {
      roomData: null,
      showLiveWebinarGallery: false,
      showWebinarPDFDownload: false,
      showZoomRegister: false,
      showOnlineTrainingPDFs: false,
      showWorkshopsGallery: false,
      showWebcastGallery: false,
    }
  },
  mutations: {
    setRoomData(state, data) {
      state.roomData = data
    },
    openshowLiveWebinarGallery(state) {
      state.showLiveWebinarGallery = true
    },
    closeshowLiveWebinarGallery(state) {
      state.showLiveWebinarGallery = false
    },
    openshowWebinarPDFDownload(state) {
      state.showWebinarPDFDownload = true
    },
    closeshowWebinarPDFDownload(state) {
      state.showWebinarPDFDownload = false
    },
    openshowZoomRegister(state) {
      state.showZoomRegister = true
    },
    closeshowZoomRegister(state) {
      state.showZoomRegister = false
    },
    openshowOnlineTrainingPDFs(state) {
      state.showOnlineTrainingPDFs = true
    },
    closeshowOnlineTrainingPDFs(state) {
      state.showOnlineTrainingPDFs = false
    },
    openshowWorkshopsGallery(state) {
      state.showWorkshopsGallery = true
    },
    closeshowWorkshopsGallery(state) {
      state.showWorkshopsGallery = false
    },
    openshowWebcastGallery(state) {
      state.showWebcastGallery = true
    },
    closeshowWebcastGallery(state) {
      state.showWebcastGallery = false
    },
  },
  actions: {},
  getters: {},
}
